import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Header from '../Header'

const PleoHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <Header
      photo={photo}
      company="Pleo"
      heading="How Pleo’s platform engineering group empowers teams to ship customer value faster"
      subheading="Pleo, one of the fastest growing SaaS/fintech companies in Europe, partnered with Swarmia to empower their engineering teams with visibility, consistency, and healthy engineering insights."
      featured={featured}
    />
  )
}

export default PleoHeader

const query = graphql`
  {
    hero: file(relativePath: { eq: "images/story/pleo/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
