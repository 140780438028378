import React from 'react'
import MetaImage from '../../assets/images/story/pleo/cover.png'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import PleoBody from '../../components/story/pleo/Content'
import PleoHeader from '../../components/story/pleo/Header'

const PleoPage = () => {
  return (
    <Layout
      title="Pleo case study"
      description="Pleo, one of the fastest growing SaaS/fintech companies in Europe, partnered with Swarmia to empower the engineering teams with visibility, consistency, and healthy engineering insights."
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <PleoHeader />
        <QuoteBlock person="martin" />
        <PleoBody />
      </CaseStudyContainer>
      <CTASection>
        Swarmia is how the best teams get better. Get started today.
      </CTASection>
    </Layout>
  )
}
export default PleoPage
